import React from "react";

export default function Introduction() {
    return (
        <section data-aos="fade-up" data-aos-duration="3000" id="introduction">
            <div className="grid p-grid-standard xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100 content">
                <div className="grid2 img-70">
                    <div className="blue-green-rectangle"></div>
                    <div className="yellow-rectangle"></div>
                    <div className="blue-rectangle"></div>
                    <div className="light-blue-rectangle"></div>
                </div>
                <div className="col-11 headline-1 fw-semi-bold">Lerne die NextGens kennen, die das Programm erfolgreich durchlaufen haben. Kontaktiere uns, wenn Du mit einem unserer Alumni in Kontakt treten möchtest!</div>
            </div>
        </section>
    )
}