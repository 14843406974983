import Albert from "../../../../assets/img/components/network/year3/Albert.png";
import Artner from "../../../../assets/img/components/network/year3/Artner.png";
import Bachmeier from "../../../../assets/img/components/network/year3/Bachmeier.png";
import Baumann from "../../../../assets/img/components/network/year3/Baumann.png";
import Bechthold from "../../../../assets/img/components/network/year3/Bechthold.png";
import Biedersberger from "../../../../assets/img/components/network/year3/biedersberger.png";
import Birmann from "../../../../assets/img/components/network/year3/Birmann.png";
import Bischof from "../../../../assets/img/components/network/year3/Bischof.png";
import Bisqolm from "../../../../assets/img/components/network/year3/Bisqolm.png";
import Blueml from "../../../../assets/img/components/network/year3/Blueml.png";
import Boehm from "../../../../assets/img/components/network/year3/Boehm.png";
import Bregenzer from "../../../../assets/img/components/network/year3/Bregenzer.png";
import Brummer from "../../../../assets/img/components/network/year3/Brummer-Lindinger.png";
import Buechner from "../../../../assets/img/components/network/year3/Buechner.png";
import Buettner from "../../../../assets/img/components/network/year3/Buettner.png";
import Daxenberger from "../../../../assets/img/components/network/year3/Daxenberger.png";
import Dippold from "../../../../assets/img/components/network/year3/Dippold.png";
import Eller from "../../../../assets/img/components/network/year3/Eller.png";
import Finger from "../../../../assets/img/components/network/year3/Finger.png";
import Fries from "../../../../assets/img/components/network/year3/Fries.png";
import Grießhammer from "../../../../assets/img/components/network/year3/Grießhammer.png";
import Hierl from "../../../../assets/img/components/network/year3/Hierl.png";
import Hoermann from "../../../../assets/img/components/network/year3/Hoermann.png";
import Junghans from "../../../../assets/img/components/network/year3/Junghans.png";
import Kamm from "../../../../assets/img/components/network/year3/Kamm.png";
import Koepf from "../../../../assets/img/components/network/year3/Koepf.png";
import Kuenstner from "../../../../assets/img/components/network/year3/Kuenstner.png";
import Legat from "../../../../assets/img/components/network/year3/Legat.png";
import Madinger from "../../../../assets/img/components/network/year3/Madinger.png";
import Meusert from "../../../../assets/img/components/network/year3/Meusert.png";
import Miedl from "../../../../assets/img/components/network/year3/Miedl.png";
import Neumann from "../../../../assets/img/components/network/year3/Neumann.png";
import Nueßler from "../../../../assets/img/components/network/year3/Nueßler.png";
import Pertramer from "../../../../assets/img/components/network/year3/Pertramer.png";
import Pilarzyk from "../../../../assets/img/components/network/year3/Pilarzyk.png";
import Popp from "../../../../assets/img/components/network/year3/Popp.png";
import Pylipp from "../../../../assets/img/components/network/year3/Pylipp.png";
import Rettler from "../../../../assets/img/components/network/year3/Rettler.png";
import Schaeflein from "../../../../assets/img/components/network/year3/Schaeflein.png";
import Schick from "../../../../assets/img/components/network/year3/Schick.png";
import Schmid from "../../../../assets/img/components/network/year3/Schmid.png";
import Schwarz from "../../../../assets/img/components/network/year3/Schwarz.png";
import Siegl from "../../../../assets/img/components/network/year3/Siegl.png";
import Teubner from "../../../../assets/img/components/network/year3/Teubner.png";
import Wagner from "../../../../assets/img/components/network/year3/Wagner.png";
import Weinhardt from "../../../../assets/img/components/network/year3/Weinhardt.png";
import Wittmann from "../../../../assets/img/components/network/year3/Wittmann.png";

let year3 = [
    {
        name: "Jessica Albert",
        title: "Detlef Albert Gebäudereinigung",
        shortDescription: `Dienstleistungen & Handwerk`,
        regions: "Selb, Oberfranken",
        pic: Albert,
    }, 
    {
        name: "Maximilian Artner",
        title: "Busservice Watzinger GmbH & Co.KG",
        shortDescription: `Verkehr & Logistik`,
        regions: "München, Oberbayern",
        pic: Artner,
    }, 
    {
        name: "Lorenz Bachmeier",
        title: "Bäcker Bachmeier GmbH & Co. KG",
        shortDescription: `Lebensmittel & Ernährung`,
        regions: "Eggenfelden, Niederbayern",
        pic: Bachmeier,
    }, 
    {
        name: "Oskar Baumann",
        title: "Baumann Automation",
        shortDescription: `Elektroindustrie`,
        regions: "Amberg, Oberpfalz",
        pic: Baumann,
    }, 
    {
        name: "Matthias Bechthold",
        title: "Günter Bechtold GmbH",
        shortDescription: `Metall & Elektronik`,
        regions: "Weilheilm i. Ob., Oberbayern",
        pic: Bechthold,
    }, 
    {
        name: "Anna Biedersberger",
        title: "BBT Biedersberger Bürotechnik GmbH",
        shortDescription: `Handel`,
        regions: "Eggenfelden, Niederbayern",
        pic: Biedersberger,
    }, 
    {
        name: "Christian Birmann",
        title: "Heibi-Metall Birmann GmbH",
        shortDescription: `Metall & Elektronik`,
        regions: "Schopfloch, Mittelfranken",
        pic: Birmann,
    }, 
    {
        name: "Benedikt Bischof",
        title: "Max Bischof GmbH",
        shortDescription: `Metall & Elektronik`,
        regions: "Obernzell, Niederbayern",
        pic: Bischof,
    }, 
    {
        name: "Jacqueline Bisquolm",
        title: "Bisquolm Spedition GmbH",
        shortDescription: `Verkehr & Logistik`,
        regions: "Reichertsheim, Oberbayern",
        pic: Bisqolm,
    },  
    {
        name: "Dajana Blüml",
        title: "Neulinger Bau GmbH",
        shortDescription: `Bau`,
        regions: "Hofkirchen, Niederbayern",
        pic: Blueml,
    },  
    {
        name: "Manuela Böhm",
        title: "PERO AG",
        shortDescription: `Metall & Elektronik`,
        regions: "Königsbrunn, Schwaben",
        pic: Boehm,
    },  
    {
        name: "Felix Bregenzer",
        title: "Bäckerei MAXL Bäck GmbH & Co. KG",
        shortDescription: `Lebensmittel & Ernährung`,
        regions: "Zellingen, Unterfranken",
        pic: Bregenzer,
    },  
    {
        name: "Christina Brummer-Lindinger",
        title: "Walter Lindinger Gebäudereinigung",
        shortDescription: `Dienstleistungen & Handwerk`,
        regions: "Altötting, Oberbayern",
        pic: Brummer,
    },  
    {
        name: "Thomas Büchner",
        title: "Holz Büchner GmbH & Co. KG",
        shortDescription: `Dienstleistungen & Handwerk`,
        regions: "Arnstorf, Niederbayern",
        pic: Buechner,
    },  
    {
        name: "Maximilian Büttner",
        title: "Spezialitätenbäckerei Büttner",
        shortDescription: `Lebensmittel & Ernährung`,
        regions: "Bad Tölz, Oberbayern",
        pic: Buettner,
    },   
    {
        name: "Stefan Daxenberger",
        title: "Daxenberger Schreinerei GmbH",
        shortDescription: `Dienstleistungen & Handwerk`,
        regions: "Seeon, Oberbayern",
        pic: Daxenberger,
    },  
    {
        name: "Lukas Dippold",
        title: "Niersberger Wohn- und Anlagenbau GmbH & Co. KG",
        shortDescription: `Bau`,
        regions: "Erlangen, Mittelfranken",
        pic: Dippold,
    },  
    {
        name: "Jonathan Eller",
        title: "Auenland Beef GmbH & Co. KG",
        shortDescription: `Lebensmittel & Ernährung`,
        regions: "Hofheim, Unterfranken",
        pic: Eller,
    }, 
    {
        name: "Franziska Finger",
        title: "Packsys GmbH",
        shortDescription: `Pharma & Gesundheit`,
        regions: "Feldafing, Oberbayern",
        pic: Finger,
    }, 
    {
        name: "Isabell Fries",
        title: "Direktion Jürgen Fries",
        shortDescription: `Finanzen, Versicherungen & Immobilien`,
        regions: "Aschaffenburg, Unterfranken",
        pic: Fries,
    },  
    {
        name: "Aurel Grießhammer",
        title: "ARCO GmbH",
        shortDescription: `Finanzen, Versicherungen & Immobilien`,
        regions: "München, Oberbayern",
        pic: Grießhammer,
    },
    {
        name: "Nina Hierl",
        title: "Heinrich Hierl e. K.",
        shortDescription: `Verkehr & Logistik`,
        regions: "Kelheim, Niederbayern",
        pic: Hierl,
    },  
    {
        name: "Michael Hörmann",
        title: "Rudolf Hörmann GmbH & Co. KG",
        shortDescription: `Bau`,
        regions: "Buchloe, Schwaben",
        pic: Hoermann,
    }, 
    {
        name: "Gabriel Junghans",
        title: "Kurt Junghans Polstergestell GmbH",
        shortDescription: `Möbel`,
        regions: "Esbach, Oberfranken",
        pic: Junghans,
    },  
    {
        name: "Johanna Kamm",
        title: "Huber Technik GmbH & Co. KG",
        shortDescription: `Metall & Elektronik`,
        regions: "Erding, Oberbayern",
        pic: Kamm,
    },  
    {
        name: "Lukas Köpf",
        title: "transco Drehautomaten GmbH",
        shortDescription: `Metall & Elektronik`,
        regions: "Füssen, Schwaben",
        pic: Koepf,
    }, 
    {
        name: "Daniel Künstner",
        title: "Jugard+Künstner GmbH",
        shortDescription: `Metall & Elektronik`,
        regions: "Unterföhring, Oberbayern",
        pic: Kuenstner,
    },
    {
        name: "Dominik Madinger",
        title: "Madinger GmbH",
        shortDescription: `Dienstleistungen & Handwerk`,
        regions: "Euerbach, Unterfranken",
        pic: Madinger,
    },  
    {
        name: "Anna Meusert",
        title: "Planen Wehner GmbH",
        shortDescription: `Dienstleistungen & Handwerk`,
        regions: "Schweinfurt, Unterfranken",
        pic: Meusert,
    },  
    {
        name: "Maximilian Miedl",
        title: "Konditorei Bäckerei Miedl",
        shortDescription: `Lebensmittel & Ernährung`,
        regions: "Bad Endorf, Oberbayern",
        pic: Miedl,
    },  
    {
        name: "Mathias Neumann",
        title: "MünchenBau Holding GmbH",
        shortDescription: `Finanzen, Versicherungen & Immobilien`,
        regions: "Grünwald, Oberbayern",
        pic: Neumann,
    }, 
    {
        name: "Quirin Pertramer",
        title: "Lipsticks GmbH",
        shortDescription: `Metall & Elektronik`,
        regions: "Dachau, Oberbayern",
        pic: Pertramer,
    }, 
    {
        name: "Joshua Pilarzyk",
        title: "Braugasthof Grosch GmbH & Co. KG",
        shortDescription: `Tourismus & Gastronomie`,
        regions: "Rödental, Oberfranken",
        pic: Pilarzyk,
    }, 
    {
        name: "Maximilian Popp",
        title: "Zöllner-Wiethoff GmbH",
        shortDescription: `Druck u. Papierverarbeitung`,
        regions: "Neustadt b. Coburg, Oberfranken",
        pic: Popp,
    }, 
    {
        name: "Norbert Pylipp",
        title: "HOLLER ELECTRONIC GmbH",
        shortDescription: `Elektroindustrie`,
        regions: "Deggendorf, Niederbayern",
        pic: Pylipp,
    }, 
    {
        name: "Constance Rettler",
        title: "Dr. Rettler Service GmbH",
        shortDescription: `Dienstleistungen & Handwerk`,
        regions: "München, Oberbayern",
        pic: Rettler,
    }, 
    {
        name: "Laura Schäflein",
        title: "Vrohstoff GmbH & CO KG",
        shortDescription: `Tourismus & Gastronomie`,
        regions: "Würzburg, Unterfranken",
        pic: Schaeflein,
    }, 
    {
        name: "Benjamin Schick",
        title: "GoodBIONICS Biotechnologie GmbH",
        shortDescription: `Pharma & Gesundheit`,
        regions: "Mauerstetten, Schwaben",
        pic: Schick,
    }, 
    {
        name: "Michael Schmid",
        title: "Sakosta Holding AG",
        shortDescription: `Bau`,
        regions: "München, Oberbayern",
        pic: Schmid,
    }, 
    {
        name: "Veronika Schwarz",
        title: "Zimmerei Holzbau Schiller GmbH & Co. KG",
        shortDescription: `Bau`,
        regions: "Kirchberg im Wald, Niederbayern",
        pic: Schwarz,
    },  
    {
        name: "Thomas Siegl",
        title: "Siegl GmbH",
        shortDescription: `Bau`,
        regions: "Ergolding, Niederbayern",
        pic: Siegl,
    },  
    {
        name: "Lukas Maximilian Teubner",
        title: "Bayerische Blumenzentrale",
        shortDescription: `Agrarwirtschaft`,
        regions: "Parsdorf b. München, Oberbayern",
        pic: Teubner,
    },  
    {
        name: "Maximilian Wagner",
        title: "service&verlag GmbH",
        shortDescription: `Druck und Papierverarbeitung`,
        regions: "Ergolding, Niederbayern",
        pic: Wagner,
    },  
    {
        name: "Felicia Winhardt",
        title: "papierschmitt GmbH",
        shortDescription: `Handel`,
        regions: "Salz, Unterfranken",
        pic: Weinhardt,
    },  
    {
        name: "Valentin Wittmann",
        title: "Birner Kunststofftechnik GmbH",
        shortDescription: `Chemie & Rohstoffe`,
        regions: "Kümmersbruck, Oberpfalz",
        pic: Wittmann,
    },   
];

export default year3;